import React from "react";
import logo from "./imprint-logo.svg";
import "./App.css";
import question from "./question.svg";
import {
  ImpContainer,
  ImpFlex,
  ImpText,
  ImpSpacer,
  ImpFlexItem,
  ImpPad,
  colors,
} from "@imprint-payments/imprint-ui";

// valid operationItems status are:
// - Operational: the system is fully functional
// - Partial: the system is partically functional
// - Scheduled Maintenance: the system is going to have scheduled maintenance
// - Outage: the system is completely down
const operationItems = [
  {
    title: "Account Login",
    status: "Operational",
    description: "User's account login on all platforms.",
  },
  {
    title: "Transaction",
    status: "Operational",
    description: "Card transaction function from all processors.",
  },
  {
    title: "Application Sign Up",
    status: "Operational",
    description: "New user application sign up from all sources.",
  },
  {
    title: "Payment",
    status: "Operational",
    description: "User's payment function on all platforms.",
  },
];

const scheduledMaintenanceItems = [
  {
    title: "Full system maintenance",
    maintenanceWindow: "2023 Oct 18 11:15 pm PST - 2023 Oct 19 00:00 am PST",
  },
  {
    title: "Full system maintenance",
    maintenanceWindow: "2023 Oct 16 11:15 pm PST - 2023 Oct 16 11:45 pm PST",
  },
];

const incidentItems = [
];

function App() {
  return (
    <ImpContainer>
      <ImpContainer
        width="100%"
        height="73px"
        backgroundColor="white"
        borderBottom="1px solid #d3d1cf"
      >
        <ImpFlex direction="column" align="center" justify="center" fluid>
          <img src={logo} />
        </ImpFlex>
      </ImpContainer>

      <ImpFlex fluid direction="row" justify="center">
        <ImpContainer width="min(756px, 100vw - 32px)">
          <ImpSpacer height="3xl" />
          <ImpPad padding="16px 24px">
            <ImpText color={colors.content.onBackground} typography="headline3">
              Current status
            </ImpText>
          </ImpPad>

          <div className="items-container">
            {operationItems.map((status, index) => (
              <OperationItems
                title={operationItems[index].title}
                status={operationItems[index].status}
                description={operationItems[index].description}
              />
            ))}
          </div>
        </ImpContainer>
      </ImpFlex>

      <ImpFlex fluid direction="row" justify="center">
        <ImpContainer width="min(756px, 100vw - 32px)">
          <ImpSpacer height="3xl" />
          <ImpPad padding="16px 24px">
            <ImpText color={colors.content.onBackground} typography="headline3">
              Scheduled maintenance
            </ImpText>
          </ImpPad>

          <div className="single-row-container">
            <ImpFlexItem flexBasis={"100%"}>
              {scheduledMaintenanceItems.map((status, index) => (
                <CellItem
                  title={scheduledMaintenanceItems[index].title}
                  text={scheduledMaintenanceItems[index].maintenanceWindow}
                />
              ))}
            </ImpFlexItem>
          </div>
        </ImpContainer>
      </ImpFlex>

      <ImpFlex fluid direction="row" justify="center">
        <ImpContainer width="min(756px, 100vw - 32px)">
          <ImpSpacer height="3xl" />

          <ImpPad padding="16px 24px">
            <ImpFlex direction="row" justify="space-between" align="center">
              <ImpFlex direction="column" justify="flex-start">
                <ImpFlex direction="row" align="center" gap="s">
                  <ImpText typography="headline3">Incidents</ImpText>
                </ImpFlex>

                { incidentItems.length === 0 && (
                  <ImpText typography="body1" color={colors.content.onBackground}>
                    None
                  </ImpText>
                )}
              </ImpFlex>
            </ImpFlex>
          </ImpPad>

          { incidentItems.length != 0 && (
            <div className="single-row-container">
              <ImpFlexItem flexBasis={"100%"}>
                {incidentItems.map((status, index) => (
                  <CellItem
                    title={incidentItems[index].title}
                    text={incidentItems[index].update}
                  />
                ))}
              </ImpFlexItem>
            </div>)}

        </ImpContainer>
      </ImpFlex>

    </ImpContainer>
  );
}

function Tooltip({ text }) {
  return (
    <div className="tooltip-container">
      <img src={question} />
      <div className="tooltip-text">
        <ImpText typography="body3" color="white">
          {text}
        </ImpText>
      </div>
    </div>
  );
}

function OperationItems({ title, description, status }) {
  const getStatusColor = () => {
    if (status === "Operational") {
      return colors.content.onBackgroundSuccess;
    }
    if (status === "Down") {
      return colors.content.onBackgroundError;
    }
    if (status === "Scheduled Maintenance") {
      return colors.content.onBackgroundWarning;
    }
    return colors.content.onBackground
  };

  return (
    <ImpFlexItem flexBasis={"50%"}>
      <CellItem
        title={title}
        text={status}
        description={description}
      />
    </ImpFlexItem>
  );
}

function CellItem({ title, description, text }) {
  const getTextColor = () => {
    if (text === "Operational") {
      return colors.content.onBackgroundSuccess;
    }
    if (text === "Down") {
      return colors.content.onBackgroundError;
    }
    if (text === "Scheduled Maintenance") {
      return colors.content.onBackgroundWarning;
    }
    return colors.content.onBackground
  };

  return (
    <ImpPad padding="16px 24px">
      <ImpFlex direction="row" justify="space-between" align="center">
        <ImpFlex direction="column" justify="flex-start">
          <ImpFlex direction="row" align="center" gap="s">
            <ImpText typography="headline3">{title}</ImpText>

            {description && <Tooltip text={description} />}
          </ImpFlex>
          <ImpText typography="body2" color={getTextColor()}>
            {text}
          </ImpText>
        </ImpFlex>
      </ImpFlex>
    </ImpPad>
  );
}

export default App;
